<div class="about-section">
    <div class="section-heading" *ngIf="!pageloading;else pageHeadingNotLoaded">
        <h1> About</h1>
    </div>
    <ng-template #pageHeadingNotLoaded>
        <div class="section-heading ">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
    </ng-template>

    <div class="row mt-4" *ngIf="!pageloading;else pageContentNotLoaded">
        <div class="col-xxxl-8 col-xxl-7 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-6 d-flex   mb-sm-3 mb-md-3 mb-lg-5 mb-xl-5 mb-xxl-3 pe-xxl-4 pe-xl-4 pe-lg-4 pe-md-3 pe-sm-3">
            <div class="card top-color-dark-blue card-back">
                <div class="card-body card-content ">
                    <div class="d-flex justify-content-center mt-3">
                        <img src="bjs_black_logo_about.png" class="small-device-academy"   alt="BJS Academy" >
                    </div>
                   <div class="card-content">
                    <p class="ps-1 pe-1">
                        BJS Academy is an online educational resource for current and future
                        surgeons. It serves as the home for all things relating to the BJS Foundation
                        as well as produces content, both original and in conversation with material
                        published in the BJS Journals.
                    </p>


                    <p class="ps-1 pe-1">BJS Academy was founded as a part of the charitable activity of BJS
                        Foundation, which owns and operates the following.</p>
                   </div>
                    <div class="d-flex justify-content-center ">
                        <a class="btn btn-common btn-darkblue  small-padding" routerLink="page/about">BJS Foundation</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xxxl-4 col-xxl-5 col-xl-6 col-lg-6  col-md-12  col-xs-6  col-sm-12 d-flex  mb-sm-3 mb-md-3 mb-lg-5 mb-xl-5 mb-xxl-3 ps-xxl-4 ps-xl-4 ps-lg-4 ps-md-3 ps-sm-3">
            <div class="card top-color-yellow card-back">
                <div class="card-body padding-award">
                    <div class="d-flex row-column"> 
                        <div class="align-items-center mt-1 change-award-flex">
                            <img src="bjs_award.png" class="bjs-about-award-logo" alt="BJS Awards" >
                        </div>
                        <div class="card-content pt-0">
                            <p class="ps-1 pe-1">
                                A celebration of excellence in surgical science, the BJS Award
                                recognises a discovery, innovation or scientific study that has changed
                                clinical practice. Awarded every two years, this international accolade
                                gives an exceptional individual the recognition they richly deserve.
                            </p>
                            <div class="d-flex award-btn-pcontainer   ">
                                <a class="btn btn-common btn-yellow" routerLink="page/bjs-award">Award</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #pageContentNotLoaded>
        <div class="row mt-4">
            <div class="col-6 col-xs-6 d-flex   mb-sm-3 mb-md-3 mb-lg-5 mb-xl-5 mb-xxl-3">
                <div class="card top-color-dark-blue card-back w-100">
                    <div class="card-body card-content">
                        <div class="d-flex justify-content-start bjs-icons">
                            <div class=" p-1 bjs-logo skeleton skeleton-img">
                            </div>
                        </div>
                        <p class="ps-1 pe-1 skeleton skeleton-text"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text skeleton-text-80"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text skeleton-text-80"> </p>
                    </div>
                </div>
            </div>

            <div class="col-6  col-xs-6  d-flex  mb-sm-3 mb-md-3 mb-lg-5 mb-xl-5 mb-xxl-3">
                <div class="card top-color-yellow card-back w-100">
                    <div class="card-body padding-award">
                        <div class="d-flex justify-content-start bjs-award mb-4">
                            <div class=" p-1 bjs-logo skeleton skeleton-img">
                            </div>
                        </div>
                        <p class="ps-1 pe-1 skeleton skeleton-text"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text skeleton-text-80"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text"> </p>
                        <p class="ps-1 pe-1 skeleton skeleton-text skeleton-text-80"> </p>
                        <div class="d-flex justify-content-center">
                            <div class=" skeleton">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </ng-template>
    <div class="row d-flex justify-content-center  " *ngIf="!pageloading;else pageContentSecondNotLoaded">
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 card-gap side-padding-left">
            <div class="card top-color-red card-back-second">
                <div class="card-body p-3 pb-4 card-padding">
                    <div class="d-flex justify-content-center mt-2 mb-2">
                        <img src="bjs_black_logo.png" class="bjs-about-academy-logo-img "  alt="BJS Academy">
                    </div>
                    <p class="small-card-p">
                        Academy content is comprised of
                        five distinct sections: Continuing
                        surgical education, Young BJS,
                        Cutting edge, Scientific surgery and
                        Surgical news.
                    </p>
                    <div class="d-flex justify-content-center">
                        <a class="btn btn-common btn-red" routerLink="bjs-academy">Content</a>
                    </div>


                </div>
            </div>
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 card-gap">
            <div class="card top-color-blue card-back-second">
                <div class="card-body p-3 pb-4 card-padding">
                    <div class="d-flex justify-content-center mt-2 mb-2">
                        <img src="bjs_journals.png" class="bjs-about-logo-img"  alt="BJS Journals" >
                    </div>
                    <p class="small-card-p">
                        The Foundation owns and
                        publishes two surgical journals,
                        BJS and BJS Open.
                    </p>
                    <div class="d-flex justify-content-center">
                        <a class="btn btn-common btn-blue" routerLink="page/bjs-journals">Journals</a>
                    </div>


                </div>
            </div>
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 card-gap">
            <div class="card top-color-purple card-back-second">
                <div class="card-body p-3 pb-4 card-padding">
                    <div class="d-flex justify-content-center mt-2 mb-2">
                        <img src="bjs_institute.png" class="bjs-about-logo-img"  alt="BJS Institute" >
                    </div>
                    <p class="small-card-p" #institute>
                        BJS Institute provides formal
                        certified online surgical courses to
                        surgeons in training and
                        established surgeons who wish to
                        develop their skills in surgical
                        writing and publishing.
                    </p>
                    <div class="d-flex justify-content-center">
                        <a class="btn btn-common btn-purple" routerLink="page/bjs-institute">Institute</a>
                    </div>


                </div>
            </div>
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 card-gap side-padding-right">
            <div class="card top-color-green card-back-second">
                <div class="card-body p-3 pb-4 card-padding">
                    <div class="d-flex justify-content-center mt-2 mb-2">
                        <img src="bjs_partners.png" class="bjs-about-logo-img"  alt="BJS Partner" >
                    </div>
                    <p class="small-card-p">
                        Championing a Partners
                        collaborative approach, the
                        Foundation offers two levels of
                        partnership, each with their own
                        unique benefits.
                    </p>
                    <div class="d-flex justify-content-center">
                        <a class="btn btn-common btn-green"
                            routerLink="/page/bjs-partners/strategic-partners">Partners</a>
                    </div>


                </div>
            </div>
        </div>
    </div>
    <ng-template #pageContentSecondNotLoaded>
        <div class="row d-flex justify-content-center  ">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 card-gap side-padding-left">
                <div class="card top-color-red card-back-second">
                    <div class="card-body p-3 pb-4 card-padding mb-5">
                        <div class="d-flex justify-content-center mt-2 mb-2">
                            <div class=" p-1 bjs-logo skeleton skeleton-img">
                            </div>
                        </div>
                        <p class=" skeleton skeleton-text"> </p>
                        <p class="skeleton skeleton-text"> </p>
                        <p class="skeleton skeleton-text"> </p>
                        <p class="skeleton skeleton-text skeleton-text-80  last-text "> </p>
                        <div class="d-flex justify-content-center  ">
                            <div class="skeleton">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 card-gap">
                <div class="card top-color-blue card-back-second">
                    <div class="card-body p-3 pb-4 card-padding mb-5">
                        <div class="d-flex justify-content-center mt-2 mb-2">
                            <div class=" p-1 bjs-logo skeleton skeleton-img">
                            </div>
                        </div>
                        <p class=" skeleton skeleton-text"> </p>
                        <p class="skeleton skeleton-text"> </p>
                        <p class="skeleton skeleton-text"> </p>
                        <p class="skeleton skeleton-text skeleton-text-80  last-text"> </p>
                        <div class="d-flex justify-content-center">
                            <div class="skeleton">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 card-gap">
                <div class="card top-color-purple card-back-second">
                    <div class="card-body p-3 pb-4 card-padding mb-5">
                        <div class="d-flex justify-content-center mt-2 mb-2">
                            <div class=" p-1 bjs-logo skeleton skeleton-img"> </div>
                        </div>
                        <p class=" skeleton skeleton-text"> </p>
                        <p class=" skeleton skeleton-text"> </p>
                        <p class=" skeleton skeleton-text"> </p>
                        <p class=" skeleton skeleton-text skeleton-text-80  last-text"> </p>
                        <div class="d-flex justify-content-center">
                            <div class="skeleton">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 card-gap side-padding-right">
                <div class="card top-color-green card-back-second">
                    <div class="card-body p-3 pb-4 card-padding mb-5">
                        <div class="d-flex justify-content-center mt-2 mb-2">
                            <div class=" p-1 bjs-logo skeleton skeleton-img"></div>
                        </div>
                        <p class=" skeleton skeleton-text"> </p>
                        <p class=" skeleton skeleton-text"> </p>
                        <p class=" skeleton skeleton-text"> </p>
                        <p class=" skeleton skeleton-text skeleton-text-80 last-text"> </p>
                        <div class="d-flex justify-content-center">
                            <div class="skeleton">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>